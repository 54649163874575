
import { defineComponent } from "@vue/runtime-core"

export default defineComponent({
  props: {
    resetButtonVisible: {
      type: Boolean,
      default: (): boolean => true,
    },
    saveButtonText: {
      Type: String,
      default: (): string => "Speichern",
    },
    resetButtonText: {
      Type: String,
      default: (): string => "Zurücksetzen",
    },
    usedOnModal: {
      Type: Boolean,
      default: (): boolean => false,
    },
  },
})
