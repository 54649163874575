<template>
  <!-- reset Button for desktop -->
  <Popper
    hover
    arrow
    append-to-body="true"
    placement="top"
    class="border-0 m-0 popper-wrapper d-none d-md-block"
  >
    <template #content>
      <div class="popper-content">zurücksetzen</div>
    </template>

    <button
      aria-hidden="true"
      class="
        btn btn-link
        flycon flycon-top flycon-24 flycon-reload
        text-orchid
        lh-0
        rounded-circle
        p-1
        border-0
      "
      aria-label="reload"
      @click="$emit('reset-all')"
    ></button>
  </Popper>

  <!-- reset Button for mobile -->
  <div
    class="
      btn btn-sm
      py-0
      d-flex d-md-none
      justify-content-center
      align-items-center
    "
    @click="$emit('reset-all')"
  >
    <span
      aria-hidden="true"
      class="
        flycon flycon-top flycon-18 flycon-reload
        text-orchid
        lh-0
        rounded-circle
        border-0
      "
    ></span>
    <span class="py-1 ps-1">zurücksetzen</span>
  </div>
</template>
<script>
import { defineComponent } from "@vue/runtime-core"
import Popper from "vue3-popper"

export default defineComponent({
  emits: ["reset-all"],
  components: {
    Popper,
  },
})
</script>
