
import router from "@/router"
import { defineComponent } from "@vue/runtime-core"
import Popper from "vue3-popper"
import { getMeasureBonusItemOutput } from "@/types"

export default defineComponent({
  props: {
    iconToggle: {
      type: Boolean,
    },
    measures: {
      type: Object,
    },
  },
  components: {
    Popper,
  },
  setup() {
    const bgColor = "red"
    function navigateTo(id: string, label: string) {
      router.push({ name: "MeasuresDetail", params: { id: id, label: label } })
    }
    return {
      navigateTo,
      bgColor,
      getMeasureBonusItemOutput: getMeasureBonusItemOutput,
    }
  },
})
