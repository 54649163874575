
// @ is an alias to /src
import { defineComponent, ref, computed } from "vue"
import { useStore } from "@/store"

import {
  ArrayFormatter,
  DurationFormatter,
  MeasurementFormatter,
  CurrencyFormatter,
  URLFormatter,
  BooleanFormatter,
} from "@/helpers"
import moment from "moment"
import Popper from "vue3-popper"

// Imported compoenents
import Pagination from "@/components/Pagination.vue"
import TestSystemContainer from "@/components/TestSystemContainer.vue"
import FilterStates from "@/components/filter/FilterStates.vue"
import FilterOverview from "@/components/filter/FilterOverview.vue"
import ListViewCards from "@/components/ListViewCards.vue"
import CardBorderedBody from "@/components/card/CardBorderedBody.vue"
import CardBordered from "@/components/card/CardBordered.vue"
import DateRangeDescriptionSnippet from "@/components/date-range/DateRangeDescriptionSnippet.vue"
import SearchEmptyState from "@/components/search/SearchEmptyState.vue"
import {
  getPlaceholder,
  ICheckboxFilterItem,
  getMeasureBonusItemOutput,
  getLivestockOutput,
} from "@/types"
import { IStateFilterItem } from "@/types/filter/IStateFilterItem"
import { ICheckboxFilter } from "@/types/filter/ICheckboxFilter"
import { DateFormatter } from "@/helpers"

export default defineComponent({
  name: "MeasuresOverview",
  components: {
    Pagination,
    TestSystemContainer,
    FilterStates,
    FilterOverview,
    ListViewCards,
    CardBorderedBody,
    CardBordered,
    DateRangeDescriptionSnippet,
    SearchEmptyState,
    Popper,
  },
  props: {
    items: {
      type: Array,
      default() {
        return [
          "Gültigkeit",
          "Bereich",
          "Lage",
          "Form",
          "Maße",
          "Prämie",
          "Laufzeit",
          "Förderkulisse",
          "Förderprogramm",
        ]
      },
    },
  },
  setup() {
    const store = useStore()
    const statesFilter = computed(
      () => store.getters.getFilterById("statesFilter") as ICheckboxFilter
    )

    const viewIconToggle = ref(false)
    const toggleCard = ref(false)
    const toggledCardIndex = ref(0)

    // Set Background Image Url
    function bgImage(src: string) {
      // let img = "@/assets/" + src
      return {
        backgroundImage: `url("${src}")`,
      }
    }

    // To toggle the Accordion card & icon correctly
    function togglingCard(index: number) {
      if (toggleCard.value === true) {
        if (index === toggledCardIndex.value) {
          toggledCardIndex.value = index
          toggleCard.value = false
        } else {
          toggledCardIndex.value = index
          toggleCard.value = true
        }
      } else {
        toggledCardIndex.value = index
        toggleCard.value = true
      }
    }

    // add measureType color to card border-color when it gets collapsed
    function addBorderColorOnCollapse(item: string) {
      return (
        "border-color:" +
        item +
        "!important;" +
        "border-width: 0.125rem !important;" +
        "transition: border-width 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28) 0s;"
      )
    }

    return {
      getSelectedStates: () => {
        const selectedStates = statesFilter.value?.items?.filter(
          (state: ICheckboxFilterItem) => {
            return state.isActive
          }
        )

        if (!selectedStates) {
          return ""
        }

        if (selectedStates.length === 1) {
          return "für " + selectedStates[0].label
        }

        if (selectedStates.length > 1) {
          return (
            "für " +
            selectedStates
              .map((state: IStateFilterItem | ICheckboxFilterItem) => {
                return state.code
              })
              .join(", ")
          )
        }

        return ""
      },
      measures: computed(() => store.getters.getMeasureOverview),
      pagination: computed(() => store.getters.getMeasurePagination),
      moment: computed(() => moment),
      bgImage,
      togglingCard,
      viewIconToggle,
      toggleCard,
      toggledCardIndex,
      arrayFormatter: ArrayFormatter,
      dateFormatter: DateFormatter,
      booleanFormatter: BooleanFormatter,
      durationFormatter: DurationFormatter,
      measurementFormatter: MeasurementFormatter,
      currencyFormatter: CurrencyFormatter,
      urlFormatter: URLFormatter,
      setViewIconToggleValue: (val: boolean) => {
        viewIconToggle.value = val
      },
      getPlaceholder: getPlaceholder,
      getMeasureBonusItemOutput: getMeasureBonusItemOutput,
      getLivestockOutput: getLivestockOutput,
      addBorderColorOnCollapse,
    }
  },
})
