import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-720b078a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card p-2 d-flex justify-content-center align-items-center text-center bg-light" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "fw-medium mb-0 pt-1 fs-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.stateItem.isActive)
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        class: "state-list-item me-1 mb-1 text-decoration-none text-dark",
        type: "button",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setItemInactive && _ctx.setItemInactive(...args)))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("img", {
            src: _ctx.stateItem.icon,
            class: "card-img rounded-0"
          }, null, 8, _hoisted_2),
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.stateItem.code), 1)
        ])
      ]))
    : _createCommentVNode("", true)
}