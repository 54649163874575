
import { computed, defineComponent, ref, watchEffect } from "vue"
// import Modal from "@/components/Modal.vue" // Todo - Modal
import Switch from "@/components/Switch.vue"
import FilterReset from "@/components/filter/FilterReset.vue"
// import FilterFooter from "@/components/FilterFooter.vue"
import { useStore } from "vuex"
import { ICheckboxFilterItem, Pagination } from "@/types"
import { FilterActionTypes } from "@/store/modules/filter/action-types"
import { MeasureActionTypes } from "@/store/modules/measure/action-types"

export default defineComponent({
  components: {
    // Modal,  // Todo - Modal
    Switch,
    FilterReset,
    // FilterFooter,
  },
  emits: ["updateIconToggle"],
  props: {
    viewIconToggle: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, { emit }) {
    const store = useStore()
    const overlay = ref(false)
    const spatially = ref([])
    const temporally = ref({
      from: "",
      to: "",
    })
    const widthFilterValue = ref({
      min: null,
      max: null,
    })
    const hectareFilterValue = ref({
      min: null,
      max: null,
    })
    const selectedFilterMenu = ref(0)
    const modalFilter = ref(false) // Set modalFilter as true, if modal is opened
    const switchToggleValue = ref("")
    const hasLimit = ref(6)
    const measureOverviewFilter = computed(
      () => store.getters.getMeasureOverviewFilter
    )

    // set default values of Switches, when filter tab changes
    watchEffect(() => {
      if (selectedFilterMenu.value !== 4 || overlay.value === false) {
        switchToggleValue.value = ""
      }
      if (selectedFilterMenu.value !== 4 || overlay.value === false) {
        switchToggleValue.value = ""
      }
    })
    const resetPaginationSetup = () => {
      const paginationSetup = computed(() => store.getters.getMeasurePagination)
      store.dispatch(
        MeasureActionTypes.UPDATE_MEASURE_PAGINATION,
        new Pagination(1, paginationSetup.value.limit)
      )
    }
    const getLimitedData = computed(() => {
      return hasLimit
    })
    // TODO - Open Modal Dialog
    // function openModal() {
    //   modalFilter.value = !modalFilter.value
    // }

    //Scroll to top
    function limitTo(data: []) {
      if (hasLimit.value !== 6) {
        hasLimit.value = 6
        window.scrollTo(0, 250)
      } else {
        hasLimit.value = data.length
      }
    }

    // add Scroll to body
    function addScroll() {
      const addBodyScroll: HTMLElement =
        document.getElementById("body") || new HTMLElement()
      return (addBodyScroll.style.overflow = "auto")
    }

    // remove Scroll from body
    function removeScroll() {
      const removeBodyScroll: HTMLElement =
        document.getElementById("body") || new HTMLElement()
      return (removeBodyScroll.style.overflow = "hidden")
    }

    return {
      overlay,
      spatially,
      temporally,
      selectedFilterMenu,
      modalFilter,
      switchToggleValue,
      measureOverviewFilter,
      hasLimit,
      getLimitedData,
      states: computed(() => measureOverviewFilter.value.statesFilter.items),
      measureTypes: computed(
        () => measureOverviewFilter.value.measureTypesFilter.items
      ),
      locations: computed(
        () => measureOverviewFilter.value.locationsFilter.items
      ),
      targetAreas: computed(
        () => measureOverviewFilter.value.targetAreaFilter.items
      ),
      forms: computed(() => measureOverviewFilter.value.formsFilter.items),
      widthFilterValue,
      hectareFilterValue,
      commitmentTimes: computed(
        () => measureOverviewFilter.value.commitmentTimesFilter.items
      ),
      scenes: computed(() => measureOverviewFilter.value.sceneFilter.items),
      supportPrograms: computed(
        () => measureOverviewFilter.value.supportProgramsFilter.items
      ),
      // Save und Reset filter werden nicht benutzt, Filterung findet live statt
      // Reset Filter
      resetFilter: () => {
        overlay.value = false // close bottom sheet in responsive view
      },
      // Save Filter
      saveFilter: () => {
        overlay.value = false // close bottom sheet in responsive view
      },
      // openModal, // Todo- Modal
      // Toggle Filter
      toggleFilter: (i: number) => {
        switchToggleValue.value = ""
        hasLimit.value = 6
        if (overlay.value === true && selectedFilterMenu.value !== i) {
          overlay.value = true
        } else {
          overlay.value = !overlay.value
          removeScroll()
        }

        selectedFilterMenu.value = i
      },
      // Get Value from Switch Toggle
      getSwitchToggleValue: (event: string) => {
        console.log("event", event)
        switchToggleValue.value = event
      },
      // checked,
      updateIconToggle: (val: boolean) => {
        emit("updateIconToggle", val)
      },
      resetAllSettings: () => {
        resetPaginationSetup()
        store
          .dispatch(FilterActionTypes.GET_MEASURE_FILTER)
          .then(() => {
            overlay.value = false
            hasLimit.value = 6
            store.dispatch(MeasureActionTypes.GET_MEASURES_OVERVIEW)
          })
          .catch((error) => {
            console.log("error", error)
          })
      },
      updateFilterItem: (item: ICheckboxFilterItem, filter: string) => {
        const filterItem = computed(() =>
          store.getters.getFilterItem(filter, item.id)
        )
        filterItem.value.isActive = !filterItem.value.isActive
        store.dispatch(FilterActionTypes.UPDATE_FILTER_ITEM, {
          item: item,
          filter: filter,
        })
      },
      updateTemporallyFilter: () => {
        resetPaginationSetup()
        store.dispatch(FilterActionTypes.UPDATE_TEMPORALLY_FILTER, {
          filterValue: {
            from: temporally.value.from,
            to: temporally.value.to,
          },
        })
      },
      isNumberKey: (evt: KeyboardEvent) => {
        var charCode = evt.which ? evt.which : evt.keyCode
        if (
          charCode == 37 ||
          charCode == 38 ||
          charCode == 39 ||
          charCode == 40
        ) {
          return true
        }
        evt.preventDefault()
        // if (
        //   charCode == 8  ||
        //   charCode == 37 ||
        //   charCode == 38 ||
        //   charCode == 39 ||
        //   charCode == 40 ||
        //   charCode == 46
        // ) {
        //   return true
        // }
        // const el: HTMLInputElement = (evt.target as HTMLInputElement) ?? null
        // if (charCode > 31 && (charCode < 48 || charCode > 57) || el.value.length >= 4)
        //     evt.preventDefault()
        // return true
      },
      updateWidthFilter: () => {
        store.dispatch(FilterActionTypes.UPDATE_WIDTH_FILTER, {
          filterValue: {
            min: widthFilterValue.value.min,
            max: widthFilterValue.value.max,
          },
        })
      },
      updateHectareFilter: () => {
        resetPaginationSetup()
        store.dispatch(FilterActionTypes.UPDATE_HECTARE_FILTER, {
          filterValue: {
            min: hectareFilterValue.value.min,
            max: hectareFilterValue.value.max,
          },
        })
      },
      getActiveFilterItemsPostfix: (filter: string) => {
        const amount = store.getters.getActiveFilterItemsAmount(filter)
        return amount !== 0 ? " • " + amount : ""
      },
      limitTo,
      addScroll,
    }
  },
})
