import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "filter-footer w-100 m-0" }
const _hoisted_2 = { class: "d-flex justify-content-between flex-column flex-sm-row" }
const _hoisted_3 = ["data-bs-dismiss"]
const _hoisted_4 = ["data-bs-dismiss"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.resetButtonVisible)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            class: "btn-link text-decoration-none link-orchid fw-medium px-0 d-flex align-items-center py-2 py-sm-0 justify-content-center justify-content-sm-start",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('discardFilterState')), ["stop"])),
            "data-bs-dismiss": _ctx.usedOnModal ? 'modal' : ''
          }, _toDisplayString(_ctx.resetButtonText), 9, _hoisted_3))
        : _createCommentVNode("", true),
      _createElementVNode("a", {
        class: "btn btn-black rounded-pill fw-medium text-white",
        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('saveFilterState')), ["stop"])),
        "data-bs-dismiss": _ctx.usedOnModal ? 'modal' : ''
      }, _toDisplayString(_ctx.saveButtonText), 9, _hoisted_4)
    ])
  ]))
}