
import { computed, defineComponent } from "vue"
import { useStore } from "vuex"
import { MeasureActionTypes } from "@/store/modules/measure/action-types"
import { Pagination } from "@/types"

export default defineComponent({
  name: "Pagination",
  setup() {
    const store = useStore()
    const pagination = computed(() => store.getters.getMeasurePagination)
    const limit = 10
    const getPaginationCopy = (actPage: number, limitPerPage: number) => {
      const copy = new Pagination(actPage, limitPerPage)
      copy.self = pagination.value.self
      copy.first = pagination.value.first
      copy.last = pagination.value.last
      copy.next = pagination.value.next
      copy.previous = pagination.value.previous
      copy.totalPages = pagination.value.totalPages
      copy.totalItems = pagination.value.totalItems

      return copy
    }

    const navigateTo = (page: number) => {
      let target = page <= 0 ? 1 : page
      if (page >= pagination.value.totalPages) {
        target = pagination.value.totalPages
      }

      const updatedPagination = getPaginationCopy(
        target,
        pagination.value.limit
      )
      store.dispatch(
        MeasureActionTypes.UPDATE_MEASURE_PAGINATION,
        updatedPagination
      )
      store.dispatch(MeasureActionTypes.GET_MEASURES_OVERVIEW)
    }

    const reformatPages = (pages: number[]): number[] => {
      if (pages.length === 0) {
        return pages
      }
      const lastPage = pages[pages.length - 1]
      if (lastPage >= pagination.value.totalPages) {
        return reformatPages(pages.slice(0, -1))
      }

      return pages
    }

    const getPagesArray = (leftOffset: number, middle: number) => {
      let visiblePages = Array(middle + leftOffset)
        .fill(0)
        .map((_, i) => i + 1)
      visiblePages = visiblePages.slice(leftOffset - 1, middle)
      return reformatPages(visiblePages)
    }

    const pagesToShow = computed(() => {
      let actPageIndex = pagination.value.actPage - 1
      let visiblePages: number[] = []
      const leftOffset = 2
      const rightOffset = pagination.value.totalPages - 3
      let leftBound = 0
      let center = 0
      switch (actPageIndex) {
        case 0:
        case 1:
          center = leftOffset + 1
          leftBound = leftOffset
          break
        case 2:
          center = leftOffset + actPageIndex
          leftBound = leftOffset
          break
        case pagination.value.totalPages - 3:
          center = actPageIndex + 2
          leftBound = rightOffset
          break
        case pagination.value.totalPages - 2:
          center = actPageIndex + 1
          leftBound = rightOffset + 1
          break
        case pagination.value.totalPages - 1:
          center = actPageIndex
          leftBound = rightOffset + 1
          break
        default:
          center = leftOffset + actPageIndex
          leftBound = center - 2
      }

      visiblePages = getPagesArray(leftBound, center)
      return visiblePages
    })

    const firstVisiblePage = computed(() => pagesToShow.value[0])
    const lastVisiblePage = computed(() => pagesToShow.value.slice(-1)[0])

    //Scroll to top
    function scrollToTop() {
      window.scrollTo(0, 0)
    }

    // }
    return {
      store: store,
      pagination: pagination,
      limit,
      pagesToShow,
      firstVisiblePage,
      lastVisiblePage,
      getPaginationCopy,
      navigateTo,
      scrollToTop,
    }
  },
})
