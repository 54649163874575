<template>
  <div class="states-wrapper">
    <div class="d-flex card p-0 rounded-1">
      <a
        class="
          card-body
          justify-content-center
          align-items-center
          text-decoration-none text-dark
        "
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#stateListModal"
      >
        <button
          aria-hidden="true"
          class="
            flycon flycon-top flycon-24 flycon-add
            lh-0
            rounded-circle
            border-0
            mx-auto
            text-color--low-emphasis
          "
        >
          ­
        </button>
        <span class="d-flex justify-content-center fw-medium" v-if="title"
          >Bundesland Hinzufügen</span
        >
      </a>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "@vue/runtime-core"

export default defineComponent({
  props: {
    title: {
      type: Boolean,
      default: true,
    },
  },
})
</script>
<style lang="scss" scoped>
.card {
  border-style: dashed;

  &-inner {
    margin-bottom: 0 !important;
  }
  &-body {
    span {
      font-size: 0.75rem;
      padding-top: 0.75rem;
    }
  }
}
.flycon {
  &-add {
    padding: 0.25rem;
    background-color: rgba(34, 34, 34, 0.05);
  }
}
</style>
