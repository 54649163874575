
import { defineComponent, ref } from "@vue/runtime-core"
import Modal from "@/components/modal/Modal.vue"
import CardBorderedBody from "@/components/card/CardBorderedBody.vue"
import CardBordered from "@/components/card/CardBordered.vue"
import FilterFooter from "@/components/filter/FilterFooter.vue"
import { useStore } from "vuex"
import { computed } from "vue"
import { getPlaceholder, IStateFilterItem } from "@/types"
import { FilterActionTypes } from "@/store/modules/filter/action-types"
import FilterStateCard from "@/components/filter/FilterStateCard.vue"
import FilterStatesAdd from "@/components/filter/FilterStatesAdd.vue"

export default defineComponent({
  components: {
    Modal,
    CardBorderedBody,
    CardBordered,
    FilterFooter,
    FilterStateCard,
    FilterStatesAdd,
  },

  setup() {
    const store = useStore()
    const hasLimit = ref(9)
    const statesFilter = computed(() =>
      store.getters.getFilterById("statesFilter")
    )

    const itemsSelected = computed(() =>
      store.getters.getActiveFilterItemsAmount("statesFilter")
    )

    let selectedItems = computed({
      get: () => {
        return statesFilter.value.items
          .map((state: IStateFilterItem) => {
            return {
              id: state.id,
              isActive: state.isActive,
            }
          })
          .filter(Boolean)
      },
      set: () => {
        return
      },
    })

    const updateSelectedItems = (id: string, isChecked: boolean) => {
      selectedItems.value.forEach((item: { id: string; isActive: boolean }) => {
        if (item.id === id) {
          item.isActive = isChecked
        }
      })
    }

    return {
      selectedItems,
      hasLimit,
      itemsSelected,
      states: computed(() => statesFilter.value?.items),
      updateSelectedItems,
      discardFilterState: () => {
        selectedItems.value.forEach(
          (item: { id: string; isActive: boolean }) => {
            item.isActive = false
          }
        )
        store.dispatch(FilterActionTypes.UPDATE_FILTER_ITEMS, {
          items: selectedItems.value,
          filter: "statesFilter",
        })
      },
      saveFilterState: () => {
        store.dispatch(FilterActionTypes.UPDATE_FILTER_ITEMS, {
          items: selectedItems.value,
          filter: "statesFilter",
        })
      },
      getPlaceholder: getPlaceholder,
    }
  },
})
