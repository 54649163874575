import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "switch" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["switch-slider", _ctx.switchToggleValue === _ctx.switchRightId ? `switch-slider-right` : ''])
    }, null, 2),
    _createElementVNode("button", {
      class: _normalizeClass(["btn border-0", _ctx.switchToggleValue === _ctx.switchLeftId ? `btn-active` : '']),
      onClick: _cache[0] || (_cache[0] = ($event: any) => {
        ;(_ctx.switchToggleValue = _ctx.switchLeftId),
          _ctx.$emit('getValue', _ctx.switchToggleValue)
      })
    }, _toDisplayString(_ctx.switchLeftLabel), 3),
    _createElementVNode("button", {
      class: _normalizeClass(["btn border-0 btn-active", _ctx.switchToggleValue === _ctx.switchRightId ? `btn-active` : '']),
      onClick: _cache[1] || (_cache[1] = ($event: any) => {
        ;(_ctx.switchToggleValue = _ctx.switchRightId),
          _ctx.$emit('getValue', _ctx.switchToggleValue)
      })
    }, _toDisplayString(_ctx.switchRightLabel), 3)
  ]))
}