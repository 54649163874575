
import { defineComponent, ref } from "@vue/runtime-core"

export default defineComponent({
  emits: ["getValue"],
  props: {
    switchLeftId: {
      type: String,
      default: "",
    },
    switchRightId: {
      type: String,
      default: "",
    },
    switchLeftLabel: {
      type: String,
      default: "",
    },
    switchRightLabel: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const switchToggleValue = ref(props.switchLeftId)

    return { switchToggleValue }
  },
})
