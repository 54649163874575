
import { defineComponent } from "@vue/runtime-core"
import { IStateFilterItem } from "@/types/filter/IStateFilterItem"
import { PropType } from "vue"
import { useStore } from "vuex"
import { FilterActionTypes } from "@/store/modules/filter/action-types"

export default defineComponent({
  props: {
    stateItem: {
      type: Object as PropType<IStateFilterItem>,
      default: (): IStateFilterItem => {
        return {
          id: "",
          code: "",
          label: "",
          icon: "",
          isActive: false,
          measuresExists: false,
        }
      },
    },
  },
  setup(props) {
    const store = useStore()
    return {
      setItemInactive: () => {
        store.dispatch(FilterActionTypes.UPDATE_FILTER_ITEM, {
          item: { id: props.stateItem.id, isActive: false },
          filter: "statesFilter",
          updateApiFilter: true,
        })
      },
    }
  },
})
